import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";

export default () => (
  <Layout>
    <SEO
      title="Commercial Gas Certificates"
      description="Looking for a landlord gas safety certificates for your London or Herfordshire based property? We also work with Management Companies to complete legal gas requirements"
      keywords="commercial gas certificates london,commercial gas certificates potters bar, commercial gas certificates hertfordshire, commercial gas certificates central london"
    />
    <Jumbotron imgClassName="img13"></Jumbotron>
    <div class="white-segment text-left">
      <h1 class="heading-color pb-2">Commercial gas safety check</h1>
      <p>
        By law every commercial premises must have a valid gas safety
        certificate. All gas appliances need to be checked annually by a
        registered Gas safe engineer, to ensure all equipment is safe and
        running as it should be. During the inspection any issues will be
        highlighted in order for them to be dealt with before they become a
        hazardous problem.
      </p>
      <p>
        We can carry out a gas safety inspection at the same time as your annual
        service.
      </p>
      <p>
        Contact us today on <strong>0800 2289 800</strong> to arrange your gas
        check for your commercial premises.
      </p>
    </div>
    <div class="grey-segment text-left">
      <h1 class="heading-color pb-2">Landlord gas safety certificate</h1>
      <p>
        By law all landlords must hold a valid annual Gas safety Certificate
        (CP12) for the rented property.
      </p>
      <p>
        This includes short and long term lets, shared accommodation, student
        lets, boarding schools, halls of residence and Airbnb.
      </p>
      <p>
        The check will ensure all gas appliances are running safely without any
        potential dangers. Any issues will be highlighted and dealt with before
        becoming a hazard.{" "}
      </p>
      <p>
        BBIS Commercial heating Ltd carry out landlord gas safety certificates
        for many London based property management companies.
      </p>
      <p>
        We can carry out a gas safety inspection at the same time as your annual
        service.
      </p>
      <p>
        Contact us today{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>{" "}
        to arrange your Landlord gas safety check for your premises.
      </p>
    </div>
  </Layout>
);
